/* istanbul ignore file */
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { cognitoService, cognitoUserPool } from 'src/utils/awsCognitoConfig';
// import AWS from 'aws-sdk';
import { CognitoIdentityProviderClient, RevokeTokenCommand } from "@aws-sdk/client-cognito-identity-provider";

export const getSession = async () => {
    try {
        return await new Promise((resolve, reject) => {
            const user = cognitoUserPool.getCurrentUser();
            if (user) {
                user.getSession((err, session: CognitoUserSession) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }
                });
            } else {
                reject();
            }
        });
    } catch (err) {
        console.log(err);
        return;
    }
};

export const getAccessToken = async()=> {
    const session = (await getSession()) as CognitoUserSession;
    return session.getAccessToken().getJwtToken();
 }

 export const getRefreshToken = async()=> {
    const session = (await getSession()) as CognitoUserSession;
    return session.getRefreshToken().getToken();
 }
export const regenerateToken = (
    session: CognitoUserSession,
    errorCalback: () => void
) => {
    if (!session) {
      errorCalback();
      return;
    }
    const refresh_token = session.getRefreshToken();
    const cognitoUser = cognitoUserPool.getCurrentUser() as any;
    console.log('regenerating Token');
    cognitoUser.refreshSession(refresh_token, (err) => {
        if (err) {
            console.log(err);
            errorCalback();
        }
    });
};


const revokeToken = async()=> {
    const client = new CognitoIdentityProviderClient({ region: "us-west-2" }); // Replace with your region
    const rToken = await getRefreshToken();
    const command = new RevokeTokenCommand({
    ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID, // Replace with your Cognito app client ID
    Token: rToken // Replace with the refresh token to revoke
    });

    try {
    const response = await client.send(command);
    console.log("Token revoked successfully:", response);
    } catch (error) {
    console.error("Error revoking token:", error);
    }
}

export const userSignOut = async()=> {
    const token = await getAccessToken();
    await revokeToken();
    cognitoService.globalSignOut({
        "AccessToken": token
    }, (err, data) => {
        if (err) {
            console.log('logging out user', err, err.message);
        } else {
            console.log('user logged out', data);
        }
    });
    localStorage.clear();
    sessionStorage.clear();
}