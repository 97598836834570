import React, { FC, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/common/Layout/Layout.component';
import ForgotPassword from './components/pages/Public/ForgotPassword/ForgotPassword.component';
import SignUp from './components/pages/Public/SignUp/SignUp.component';
import SignIn from './components/pages/Public/SignIn/SignIn.component';
import Program from './components/pages/Private/Program/Program.component';
import Landing from './components/pages/Private/Landing/Landing.component';
import { getSession, regenerateToken, userSignOut } from './libraries/auth.library';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useAuthenticationDispatch, useAuthenticationState } from './contexts/authentication.context';
import { useNotificationDispatch } from './contexts/notification.context';
import Lang from './libraries/language';
import { MOLIdleTime } from './components/common/CommonUI/molecules/MOLIdleTime/MOLIdleTime.component';
import { cognitoUserPool } from './utils/awsCognitoConfig';

const AppRouter: FC = () => {
    const { isAuthenticated } = useAuthenticationState();
    const authDispatch = useAuthenticationDispatch();
    const notificationDispatch = useNotificationDispatch();

    const errorCalback = () => {
        notificationDispatch({
            type: 'ERROR',
            payload: Lang.MSG_LOGIN_AGAIN_ERROR,
        });
        authDispatch({
            type: 'CHANGE_AUTHENTICATION_STATE',
            payload: {
                isAuthenticated: false,
                authenticatedUserEmail: '',
                userName: '',
            },
        });
        location.reload();
    };

    const refereshToken = async () => {
        const session = (await getSession()) as CognitoUserSession;
        if (!session) {
            location.reload();
        }
        const exp = session.getAccessToken().getExpiration();
        const expDate = new Date(new Date(exp * 1000));
        const currentDate = new Date();
        let diffInSeconds = (expDate.getTime() - currentDate.getTime()) / 1000;
        diffInSeconds /= 60;
        const diffinMinutes = Math.abs(Math.round(diffInSeconds));

        console.log("diffinMinutes", diffinMinutes);
        if (diffinMinutes <= 15) {
            regenerateToken(session, errorCalback);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                const session = (await getSession()) as CognitoUserSession;
                regenerateToken(session, errorCalback);
            })();
            const timeInterval = setInterval(refereshToken, 50 * 60000);

            return () => clearInterval(timeInterval);
        }
        return () => {
            // Do nothing
        };
    }, [isAuthenticated]);

    useEffect(() => {
        (async () => {
            const session = (await getSession()) as CognitoUserSession;
            if (session) {
                authDispatch({
                    type: 'CHANGE_AUTHENTICATION_STATE',
                    payload: {
                        isAuthenticated: true,
                        authenticatedUserEmail:
                            session.getIdToken().payload.email,
                        userName: session.getIdToken().payload.given_name,
                    },
                });
            } else {
                authDispatch({
                    type: 'CHANGE_AUTHENTICATION_STATE',
                    payload: {
                        isAuthenticated: false,
                        authenticatedUserEmail: '',
                        userName: '',
                    },
                });
            }
        })();
    }, []);

    const logout = async() => {
        const user = cognitoUserPool.getCurrentUser();

        const uID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID || "";
        const uName = user?.getUsername() || '';
        console.log('AppRouter uNameData: ', uName, uID, user, user?.getUserAttributes((err, data) => {
            if (err) {
                console.log('user error', err);
            } else {
                console.log('user ', data);
            }
        }), user?.getUsername());
        await userSignOut();
        // cognitoService.adminUserGlobalSignOut({
        //     UserPoolId: uID,
        //     Username: uName
        // }, (err, data) => {
        //     if (err) {
        //         console.log('error logging out user', err, err.stack);
        //     } else {
        //         console.log('user logged out', data, uName);
        //     }
        // })
        // user?.signOut();
        // location.reload();
    };

    return (
        <Router>
            <Layout>
                {isAuthenticated ? (
                    <MOLIdleTime
                        timeout={Number(
                            process.env.REACT_APP_IDLE_TIMEOUT_MINUTES
                        )}
                        onLogout={() => logout()}
                    />
                ) : null}
                <Routes>
                    {isAuthenticated ? (
                        <>
                            <Route
                                path="/apply-for-program"
                                element={<Program />}
                            />
                            <Route path="/" element={<Landing />} />
                            <Route path="*" element={<Landing />} />
                        </>
                    ) : (
                        <>
                            <Route
                                path="/forgot-password"
                                element={<ForgotPassword />}
                            />
                            <Route path="/sign-up" element={<SignUp />} />
                            <Route path="/" element={<SignIn />} />
                            <Route path="*" element={<SignIn />} />
                        </>
                    )}
                </Routes>
            </Layout>
        </Router>
    );
};

export default AppRouter;
