import React, { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FPForm from './FPForms/FPForm.component';
import FPOTPForm from './FPForms/FPOTPForm.component';
import FPResetPasswordForm from './FPForms/FPResetPasswordForm.component';
import { ForgotPasswordFormDataType } from '../../../../types/forgotPassword.types';
import { useNotificationDispatch } from 'src/contexts/notification.context';
import Lang from '../../../../libraries/language';
import {
    confirmPassword,
    forgotPassword,
    sendEmailOnForgotPasswordSuceess,
    signIn,
} from 'src/services/authentication.services';
import { cognitoUserPool } from 'src/utils/awsCognitoConfig';
import { userSignOut } from 'src/libraries/auth.library';

const ForgotPassword: FC = () => {
    const [page, setPage] = useState<number>(1);
    const [formData, setFormData] = useState<
        ForgotPasswordFormDataType | Partial<ForgotPasswordFormDataType>
    >({});
    const navigate = useNavigate();
    const notificationDispatch = useNotificationDispatch();
    const [displayError, setDisplayError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const newPassword = useRef('');

    const navigatePageHandler = (direction: 'previous' | 'next') => {
        setPage((prevPage) =>
            direction === 'previous' ? prevPage - 1 : prevPage + 1
        );
    };

    const submitEmailSuccess = (session) => {
        if (session) {
            navigatePageHandler('next');
        }
        setIsLoading(false);
    };

    const submitEmailError = (err) => {
        console.log(err);
        setIsLoading(false);
        setDisplayError(err.message);
        setTimeout(() => {
            setDisplayError('');
        }, 3000);
    };

    const onSubmitEmailHandler = async (email: string) => {
        setFormData({ email: email });
        setIsLoading(true);
        forgotPassword({ email: email }, submitEmailSuccess, submitEmailError);
    };

    const onSubmitOTPHandler = (OTP: string) => {
        setFormData((prev) => {
            return { ...prev, verificationCode: OTP };
        });

        navigatePageHandler('next');
    };

    const submitResetPasswordSuccess = () => {
        notificationDispatch({
            type: 'SUCCESS',
            payload: Lang.MSG_PASSWORD_UPDATED_SUCCESSFULLY,
        });
        setIsLoading(false);
        signIn(
            { email: formData.email as string, password: newPassword.current },
            async() => {
                await sendEmailOnForgotPasswordSuceess({USERNAME: formData.email as string})

                const user = cognitoUserPool.getCurrentUser();
                user?.getSession((err: any, result: any) => {
                    if (result) {
                        userSignOut();
                    }
                });
            },
            (err) => {
                console.log('err', err);
            }
        );
        navigate('/');
    };

    const submitResetPasswordError = (err) => {
        console.log(err);
        setIsLoading(false);
        setDisplayError(err.message);
        setTimeout(() => {
            setDisplayError('');
        }, 3000);
    };

    const onSubmitResetPasswordHandler = async (password: string) => {
        setIsLoading(true);
        newPassword.current = password;
        confirmPassword(
            {
                email: formData.email as string,
                verificationCode: formData.verificationCode as string,
                newPassword: password,
            },
            submitResetPasswordSuccess,
            submitResetPasswordError
        );
    };

    let currentPageContent: JSX.Element;
    switch (page) {
        case 1:
            currentPageContent = (
                <FPForm
                    onSubmitEmailHandler={onSubmitEmailHandler}
                    isLoading={isLoading}
                    hasError={displayError}
                />
            );
            break;
        case 2:
            currentPageContent = (
                <FPOTPForm
                    onSubmitOTPHandler={onSubmitOTPHandler}
                    navigatePageHandler={navigatePageHandler}
                    formData={formData}
                />
            );
            break;
        case 3:
            currentPageContent = (
                <FPResetPasswordForm
                    onSubmitResetPasswordHandler={onSubmitResetPasswordHandler}
                    navigatePageHandler={navigatePageHandler}
                    isLoading={isLoading}
                    hasError={displayError}
                />
            );
            break;
        default:
            currentPageContent = (
                <FPForm
                    onSubmitEmailHandler={onSubmitEmailHandler}
                    isLoading={isLoading}
                    hasError={displayError}
                />
            );
            break;
    }

    return currentPageContent;
};

export default ForgotPassword;
